import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Tours from "../components/Tours"

const TourPage = ({ data: { allStrapiTours: { nodes: tours } } }) => {

  return (
    <Layout>
      <SEO title="Tours" />
      <section className="tour-page">
        <Tours tours={tours} title={"Tour Packages"} page/>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiTours {
      nodes {
        id
        title
        type
        description
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
export default TourPage
